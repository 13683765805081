import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  goBackFunction: () => void;
  translation?: string;
}

export const GoBackButton = ({
  goBackFunction,
  translation = 'common:actions.back',
}: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={goBackFunction}
      size="large"
      sx={{ width: { xs: '100%', lg: 'unset' } }}
      variant="outlined"
      color="primary"
    >
      {t(translation)}
    </Button>
  );
};
