import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Chip from '@mui/material/Chip/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GiftCodeStatus } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useTranslation } from 'react-i18next';
import { GoBackButton } from '../utils';
import { GiftCodeStoreStore } from '../../../store/GiftCodeStore.ts';
import ImageAsync from '../../../../../Components/ImageAsync';

interface Props {
  giftCode: GiftCodeStoreStore['giftCode'];
  applyCode: () => void;
  resetStore: () => void;
}

const View = ({ giftCode, applyCode, resetStore }: Props): JSX.Element => {
  const { t } = useTranslation('giftCodeSeller');

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5 }}>
        {t('form.verificationTitle')}
      </Typography>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('form.giftCodeNumber')}>
            {giftCode?.code}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} mb={3}>
          <NotEditableTextClassic
            label={t('form.giftCodeStatus')}
            InputLabelProps={{}}
          >
            <Chip
              label={t(`states.${giftCode?.status}`)}
              color={
                giftCode?.status === GiftCodeStatus.Redeemed
                  ? 'default'
                  : 'success'
              }
            />
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('form.giftTitle')}>
            {' '}
          </NotEditableTextClassic>
        </Grid>
        <Box
          sx={{
            backgroundColor: 'white',
            margin: '1rem',
            padding: '1rem',
            width: '100%',
          }}
        >
          <Grid item xs={12}>
            <ImageAsync src={giftCode?.type.image?.publicUrl} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" component="p" marginBottom={1}>
              {giftCode?.type.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{giftCode?.type.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box />
          </Grid>
        </Box>
      </Grid>

      {giftCode?.status === GiftCodeStatus.Sent && (
        <Button
          size="large"
          color="primary"
          sx={{ width: { xs: '100%', lg: 'unset', marginRight: '1rem' } }}
          onClick={applyCode}
        >
          {t('form.btnApply')}
        </Button>
      )}

      <GoBackButton goBackFunction={resetStore} />
    </>
  );
};

export default View;
