import Typography from '@mui/material/Typography';
import {
  GiftCodeStoreStore,
  useGiftCodeStore,
} from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { GoBackButton } from '../utils';
import { Button } from '@mui/material';

const selectStoreData = (s: GiftCodeStoreStore) => ({
  completeDetailPhase: s.completeDetailPhase,
  giftCode: s.giftCode,
  resetStore: s.resetStore,
});

const PhaseComplete = (): JSX.Element => {
  const { giftCode, resetStore } = useGiftCodeStore(selectStoreData, shallow);
  const { t } = useTranslation('giftCodeSeller');
  return (
    <>
      {' '}
      <Typography variant="h1" sx={{ mb: 2 }}>
        {t('form.giftCodeApplied')}
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {t('form.giftCodeAppliedInfo', {
          giftCode: giftCode?.code.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3'),
        })}
      </Typography>
      <Typography sx={{ mb: 3 }}>{new Date().toISOString()}</Typography>
      <Button
        href="/"
        size="large"
        color="primary"
        sx={{ width: { xs: '100%', lg: 'unset', marginRight: '1rem' } }}
      >
        {t('form.btnFinish')}
      </Button>
      <GoBackButton
        goBackFunction={resetStore}
        translation="giftCodeSeller:form.btnNewSearch"
      />
    </>
  );
};

export default PhaseComplete;
