import { CheckGiftCodeMutation } from 'Apollo/graphql';
import create from 'zustand';

export type GiftCodePhase = 'init' | 'detail' | 'complete';
type GiftCodeStoreState = {
  phase: GiftCodePhase;
  giftCode: CheckGiftCodeMutation['checkGiftCode'] | null;
};

export type GiftCodeStoreStore = GiftCodeStoreState & {
  completeInitPhase: (result: CheckGiftCodeMutation['checkGiftCode']) => void;
  completeDetailPhase: () => void;
  resetStore: () => void;
};

export const INIT_STATE: GiftCodeStoreState = {
  phase: 'init',
  giftCode: null,
};

export const useGiftCodeStore = create<GiftCodeStoreStore>((set) => ({
  ...INIT_STATE,
  completeInitPhase: (result) =>
    set((state) => ({
      ...INIT_STATE,
      phase: 'detail',
      giftCode: result,
    })),
  completeDetailPhase: () =>
    set((state) => ({
      ...state,
      phase: 'complete',
    })),
  resetStore: () =>
    set((state) => ({
      ...INIT_STATE,
    })),
}));
