import { useRedeemGiftCodeMutation } from 'Apollo/graphql';
import {
  GiftCodeStoreStore,
  useGiftCodeStore,
} from 'Modules/GiftCodeSeller/store/GiftCodeStore';
import shallow from 'zustand/shallow';
import View from './View';

const selectStoreData = (s: GiftCodeStoreStore) => ({
  completeDetailPhase: s.completeDetailPhase,
  giftCode: s.giftCode,
  resetStore: s.resetStore,
});

const PhaseDetail = (): JSX.Element => {
  const { completeDetailPhase, giftCode, resetStore } = useGiftCodeStore(
    selectStoreData,
    shallow,
  );

  const [redeemGiftCodeMutation] = useRedeemGiftCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.redeemGiftCode) {
        completeDetailPhase();
      }
    },
    onError: () => resetStore(),
  });

  const applyGiftCode = () =>
    redeemGiftCodeMutation({
      variables: {
        redeemGiftCodeInput: {
          code: giftCode?.code ?? '',
        },
      },
    });
  return (
    <View
      giftCode={giftCode}
      applyCode={applyGiftCode}
      resetStore={resetStore}
    />
  );
};

export default PhaseDetail;
